.clickable:hover{
    font-weight:bold;
    cursor:pointer;
}
.steps .content{
    margin:0px;
}
.content {
    background-color: #f0f2f5;
}
.invalid {
    color:orangered;
}
.bg-primary, .bg-dark{
    background-color: #268036 !important;
}
.sidebar .nav-item .nav-link{
    border: 0.0625rem solid #268036
}
.sidebar .nav-item.active > .nav-link{
    background-color: #7DD756;
    border: 0.0625rem solid #268036;
    color: #000 
}
.sidebar .nav-item.active .sidebar-icon{
    color: #000 !important;
}
.navbar-theme-primary:not(.headroom){
    background: #268036;
}
.btn-secondary{

    background-color: #7DD756 !important;
    border: 0.0625rem solid #268036;
    color: #000 
}
body{
    background: #efefef !important;
}
.full.container{
    width:100% !important;
    max-width: 100% !important;
}
/*!
 * Start Bootstrap - Full Slider (https://startbootstrap.com/template-overviews/full-slider)
 * Copyright 2013-2017 Start Bootstrap
 * Licensed under MIT (https://github.com/BlackrockDigital/startbootstrap-full-slider/blob/master/LICENSE)
 */

 .carousel-item {
    height: 100vh;
    min-height: 300px;
    background: no-repeat center center scroll;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  


.carousel-indicators {
    position: absolute;
    right: 0;
    bottom: 10px;
    left: 0;
    z-index: 15;
    display: flex;
    justify-content: center;
    padding-left: 0;
    list-style: none;
  
   
  }


.btn-file {
    position: relative;
    overflow: hidden;
    padding: 5px 15px;
    font-size: 14px !important;
    text-transform: capitalize;
  }
  .btn-file input[type="file"] {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 100%;
    min-height: 100%;
    font-size: 100px;
    text-align: right;
    filter: alpha(opacity=0);
    opacity: 0;
    outline: none;
    cursor: inherit;
    display: block;
  }